import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import BoutonAcheterTirageAvecPrix from "../../../composants/boutique/BoutonAcheterTirageAvecPrix"
import Image from "../../../composants/image/Image"
import LayoutGalerieImageInteractive from "../../../composants/layout/LayoutGalerieImageInteractive"
import OpenSeaDragonViewer from "../../../composants/image/OpenSeaDragonViewer"

import {
  obtenirPage,
  obtenirImages,
  obtenirImage,
} from "../../../js/client-es/utils"
import fragmentsPages from "../../../js/client-es/fragments/fragments-pages"

export const requete = graphql`
  {
    pageMegalaxyZoom: allPagesCompilationYaml(
      filter: { code: { eq: "megalaxyZoom" } }
    ) {
      ...FragmentPageYaml
    }
    imageAVendre: allImagesCompilationYaml(
      filter: { code: { in: ["megalaxyZoom"] } }
    ) {
      nodes {
        ...FragmentDonneesImages
      }
    }
    imagesPaysage: allImagesCompilationYaml(
      filter: { code: { in: ["voieLacteeMegalaxyZoom"] } }
    ) {
      ...FragmentImagePaysage
    }
    imagesPaysageMoyen: allImagesCompilationYaml(
      filter: { code: { in: ["astroPixelProcessorMegalaxyZoom"] } }
    ) {
      ...FragmentImagePaysageMoyen
    }
    imagesPortrait: allImagesCompilationYaml(
      filter: { code: { in: ["megalaxyZoomAnnotee"] } }
    ) {
      ...FragmentImagePortrait
    }
    imagesPortraitPetit: allImagesCompilationYaml(
      filter: { code: { in: ["moiSousLesEtoiles"] } }
    ) {
      ...FragmentImagePortraitPetit
    }
  }
`

export default function MegalaxyZoom(props) {
  const resultatsRequete = useStaticQuery(requete)
  const page = obtenirPage(resultatsRequete, "pageMegalaxyZoom")
  const images = obtenirImages(
    resultatsRequete,
    "imageAVendre",
    "imagesPaysage",
    "imagesPaysageMoyen",
    "imagesPortrait",
    "imagesPortraitPetit"
  )

  const image = {
    xmlns: "http://schemas.microsoft.com/deepzoom/2009",
    Format: "jpg",
    Overlap: "1",
    Size: { Height: "16831", Width: "13655" },
    TileSize: "256",
    Url: "/ressources/deep-zoom/megalaxyzoom/tuiles/",
  }

  const composantImageInteractive = <OpenSeaDragonViewer image={image} />

  return (
    <LayoutGalerieImageInteractive
      page={page}
      propsPage={props}
      composantImageInteractive={composantImageInteractive}
      codePageRetour="galerieZoomsEt360Degres"
    >
      <section className="section-conteneur">
        <h2 className="section-titre">Avant-propos</h2>
        <p>
          Cette image de <strong>230 millions de pixels</strong> présente une
          vue détaillée du centre galactique. Cette région du ciel nocturne très
          brillante s’étire de la constellation du Scorpion jusqu’à la
          constellation de la Couronne Australe. Réaliser cette image était{" "}
          <strong>impossible depuis la France</strong> car le centre galactique
          s’élève à peine au-dessus de l’horizon. Sans oublier les ravages de la
          pollution lumineuse… Les acquisitions ont donc été réalisées dans
          l’hémisphère Sud, sous les plus beaux ciels du monde lorsque le centre
          galactique était au zénith : 2 voyages au Chili (2016 et 2018) et 1
          voyage en Namibie (2019).
        </p>
        <p>
          Là-bas, lors de l’automne et de l’hiver austral,{" "}
          <strong>le centre galactique est au zénith</strong> et les étoiles
          brillent tellement qu’elles éclairent une nuit dépourvue de la moindre
          pollution lumineuse. C’est une lumière venue du passé. Le noir n’est
          donc pas complet et une fois nos yeux habitués, il règne comme une
          ambiance de « petite Lune » : il est possible de circuler sans lampe
          frontale. Je vous invite à découvrir cette extraordinaire région du
          ciel, riche en étoiles dorées, nébuleuses sombres et colorées.
        </p>
        <p>Très belle promenade céleste.</p>
        <div id="galerie-image-interactive-conteneur-achat-tirage-avec-prix">
          <BoutonAcheterTirageAvecPrix
            image={obtenirImage(images, "megalaxyZoom")}
            langue={page.langue}
          />
        </div>
      </section>
      <section className="section-conteneur">
        <h2 className="section-titre">L’image en chiffres</h2>
        <ul>
          <li>
            Temps de pose total : <strong>38 heures et 40 minutes</strong>
          </li>
          <li>
            Nombre d’images empilées : <strong>2320</strong>
          </li>
          <li>
            Dimensions finales : 13655 * 16831 pixels (
            <strong>229,8 mégas pixels)</strong>
          </li>
          <li>
            37 tuiles ont été assemblées pour créer la mosaïque, chacune
            représentant une pose d’environ 1 heure (ou plus quand j’oubliais de
            couper l’intervallomètre)
          </li>
          <li>
            Poids du fichier .tif final (compressé) : <strong>1,98 go</strong>
          </li>
          <li>
            Temps de calcul total : je ne sais plus trop, mais chaque calcul de
            la mosaïque prenait environ 10 heures. Comme j’en ai lancé une bonne
            quinzaine pour affiner les réglages, mon PC a peut-être tourné au
            total plus de 150 heures juste pour calculer l’image :)
          </li>
        </ul>
        <Image
          image={obtenirImage(images, "moiSousLesEtoiles")}
          langue={page.langue}
          afficherTitre={false}
        />
      </section>
      <section className="section-conteneur">
        <h2 className="section-titre">Version annotée</h2>
        <p>
          Les 3 constellations ont été dessinées et 46 objets identifiés sur
          cette image annotée :
        </p>
        <Image
          image={obtenirImage(images, "megalaxyZoomAnnotee")}
          langue={page.langue}
          afficherTitre={false}
        />
      </section>
      <section className="section-conteneur">
        <h2 className="section-titre">Situation</h2>
        <p>
          L’image couvre les régions des constellations du Sagittaire, du
          Scorpion et de la Couronne Australe qui se trouvent autour du bulbe
          galactique, une région extrêmement riche en objets célestes.
        </p>
        <Image
          image={obtenirImage(images, "voieLacteeMegalaxyZoom")}
          langue={page.langue}
        />
      </section>
      <section className="section-conteneur">
        <h2 className="section-titre">Matériels et logiciels</h2>
        <p>
          Les acquisitions ont été réalisées avec un Canon EOS 6D refiltré
          Astrodon réglé à 800 ISO et un objectif Canon EF 135mm F2@F2.5. Les
          montures étaient une Vixen GPD2 (Chili 2016) puis une petite
          Skywatcher Star Adventurer (Chili 2018, Namibie 2019). Ces montures
          n’étant pas automatisables, le cadrage était donc réalisé manuellement
          au live view avec donc beaucoup de chevauchements… D’où le grand
          nombre de tuiles. Les prétraitements et l’assemblage de la mosaïque
          ont été réalisés avec le logiciel Astro Pixel Processor et le
          traitement final a été effectué sous Photoshop.
        </p>
        <Image
          image={obtenirImage(images, "astroPixelProcessorMegalaxyZoom")}
          langue={page.langue}
          afficherTitre={false}
        />
      </section>
    </LayoutGalerieImageInteractive>
  )
}
